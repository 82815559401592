@import './src/colors.sass'
@import "~leaflet/dist/leaflet.css"
@import "~leaflet.markercluster/dist/MarkerCluster.css"
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

*
    box-sizing: border-box    

html, body
    font-family: 'Open Sans', sans-serif
    width: 100vw
    min-height: 100vh
    margin: 0
    padding: 0
    position: relative

// Loading inicial
.loader_start
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(255, 255, 255, 0.5) // Fondo semitransparente
    display: flex
    align-items: center
    justify-content: center
    z-index: 1000

    .dots
        display: flex
        gap: 8px

        div
            width: 15px
            height: 15px
            background-color: #333
            border-radius: 50%
            animation: bounce 1.5s infinite ease-in-out

        div:nth-child(1)
            animation-delay: 0s

        div:nth-child(2)
            animation-delay: 0.2s

        div:nth-child(3)
            animation-delay: 0.4s

@keyframes bounce
    0%, 80%, 100%
        transform: scale(0)
        opacity: 0.3

    40%
        transform: scale(1)
        opacity: 1

.center
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    -webkit-justify-content: center
    -moz-justify-content: center
    -ms-justify-content: center
    -o-justify-content: center
    justify-content: center
    -webkit-align-items: center
    -moz-align-items: center
    -ms-align-items: center
    -o-align-items: center
    align-items: center

.justify-between
    -webkit-justify-content: space-between
    -moz-justify-content: space-between
    -ms-justify-content: space-between
    -o-justify-content: space-between
    justify-content: space-between
  

input[type="number"]
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button 
        -webkit-appearance: none

.row
    margin: 0
    padding: 0

.scroll
    &::-webkit-scrollbar
        width: 7px
        background-color: rgba(white, .2)
        -webkit-border-radius: 5px
        border-radius: 5px

    &::-webkit-scrollbar-track
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
        -webkit-border-radius: 10px
        border-radius: 10px

    &::-webkit-scrollbar-thumb
        -webkit-border-radius: 5px
        border-radius: 5px
        background: $borderHeader
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)

    ::-webkit-scrollbar-thumb:window-inactive
        background: $borderHeader

.scroll-h
    &::-webkit-scrollbar
        height: 7px
        background-color: rgba(white, .2)
        -webkit-border-radius: 5px
        border-radius: 5px

    &::-webkit-scrollbar-track
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
        -webkit-border-radius: 10px
        border-radius: 10px

    &::-webkit-scrollbar-thumb
        -webkit-border-radius: 5px
        border-radius: 5px
        background: $borderHeader
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)

    ::-webkit-scrollbar-thumb:window-inactive
        background: $borderHeader

.color-maint
    color: $maintenance !important

.color-borderHeader
    color: $colorModal !important

.bg-maint
    background-color: $maintenance !important

.bg-borderHeader
    background-color: $borderHeader !important

.cdk-drag-placeholder
    .content_right
        .name_task
            display: block
            font-size: .9rem
            color: $textTask
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis


.fw-500
    font-weight: 500

.fz-6
    font-size: .6rem


.fz-7
    font-size: .7rem

.fz-8
    font-size: .8rem

.fz-85
    font-size: .85rem

.fz-9
    font-size: .9rem


.bg-borderHeader
    background-color: $borderHeader

.bg-bgAssigned
    background-color: $bgAssigned

.bg-bgMaint
    background-color: $bgMaint

.bg-bgAMI
    background-color: $bgAMI

.bg-bgNotOperational
    background-color: $bgNotOperational

.container-form
    width: 100%
    // height: 100%
    // position: absolute
    // top: 100px
    // left: 0

    .body
        width: 50%
        padding: 20px 0
        margin: 0 auto

        form
            max-width: 1200px
            align-content: center
      

        .loader-container
            width: 100%
            padding: 10px						

        .form-group

            .body-title
                font-weight: 100
                color: #C8C9CD

        .container-input
            position: relative
            // padding: 0 10px

            .option-placeholder
                position: absolute
                top: 15px
                left: 15px
                color: #888da8
                font-size: .85rem
                line-height: 1.42857
                pointer-events: none
                transition: .3s ease all

            .icon-calendar
                position: absolute
                top: 15px
                right: 20px
                color: $borderHeader
                transition: .3s ease all

            .option-input
                padding: 2.1rem .5rem .7rem
                line-height: 1.75
                font-size: .875rem
                border-width: 0 0 1px 0
                border-color: $borderHeader
                border-radius: 0

            &:focus
                border-color: $borderHeader
                box-shadow: none

                & ~ .control-label
                    top: 5px
                    font-size: 11px
                    line-height: 1.07143

                & ~ .icon-calendar
                    top: 5px									          

            &:valid ~ .control-label
                top: 5px
                font-size: 11px
                line-height: 1.07143            

            &.number, &.date

                &::-webkit-inner-spin-button, 
                &::-webkit-outer-spin-button 
                    -webkit-appearance: none
                    margin: 0
            
            &.error
                border-color: red
                

            .option-label
                color: #888da8
                font-size: .85rem
                line-height: 1.42857
                margin-top: 10px
                padding: 10px 10px 7px 10px
                border-bottom: 1px solid $borderHeader

            &.error
                border-color: red

            &.activated
                border: 0 !important
                margin-top: 0
                color: $textBg

                .text-end
                    padding-left: 10px
          
            &.preview
                border: 0

                .value-select-email
                    font-size: 1.5rem
                    color: $textBg

            .icon-down
                color: $borderHeader
                float: right
                font-size: 16px
                margin-top: 5px
                cursor: pointer
            
            .control-label-select
                margin: 0
                color: #888da8
                font-size: .85rem
                line-height: 1.42857           

            .container-select
                width: 92%
                height: fit-content
                max-height: 140px
                z-index: 1
                overflow-y: auto
                position: absolute
                bottom: 0
                top: 100%
                background-color: $bgContainers
                border-radius: 0 0 10px 10px

            &:hover
                visibility: visible

            .select__item
                width: 100%
                height: 35px
                color: white
                cursor: pointer
                text-align: center
                padding: 10px 5px

                &:hover
                    background-color: $bgSelectHover

                &:last-child
                    border-radius: 0 0 10px 10px

            .value-confirm
                display: block
                padding-top: 35px
                color: $textBg
                font-size: .85rem
                line-height: 1.42857
                padding-left: 10px         

            .icon_calendar
                font-size: 25px
                color: #9a9fbf
                position: absolute
                top: 35%
                right: 15px
                cursor: pointer

            .icon_select
                position: absolute
                top: 40%
                right: 20px
                font-size: 20px
                cursor: pointer


        .less-more
            width: 30px
        
            .icon-more, .icon-less
                font-size: 1.2rem
                color: $borderHeader
                cursor: pointer
                width: 30px
                height: 100%
                float: left

        .row
            .form-file
                display: flex
                align-items: flex-end
                margin-bottom: 1rem
                position: relative

            .btn-file
                color: $textAta
                font-size: .7rem
                height: 22px
                padding: 0 5px
                border-radius: 5px
                border: 1px solid $borderHeader
                position: relative

                .input_file
                    position: absolute
                    width: 100%
                    height: 100%
                    top: 0
                    left: 0
                    opacity: 0
                    cursor: pointer
          
            .text-file
                flex: 1
                margin-left: 5px
                border-bottom: 1px solid $borderHeader
                font-size: .85rem
                padding-left: 10px
          
            .text-info-file
                position: absolute
                top: 102%
                left: 0
                color: rgba(gray, .5)
                font-size: .8rem
                margin-left: 15px

            .form-group
                position: relative

            .label
                color: #888da8
                font-size: .85rem
                line-height: 1.42857         

            .form-control
                border: 0
                border-radius: 0
                padding-bottom: 0
                padding-top: 15px
                border-bottom: 1px solid $borderHeader
                box-shadow: none
                font-size: .9rem

                &.error
                    border-bottom: 1px solid red !important           

                &:focus
                    box-shadow: none

                & ~ .label-placeholder
                    top: 3px
                    font-size: 0.85rem
                    line-height: 0.77143

                & ~ .icon-calendar
                    top: 5px								          

                &:valid ~ .label-placeholder
                    top: 3px
                    font-size: 0.85rem
                    line-height: 0.77143


            textarea
                border: 1px solid $borderHeader !important
                resize: none
                border-radius: 5px !important
                padding-top: 5px !important
            
                &.error
                    border: 1px solid red !important                 
          
            .label-placeholder
                position: absolute
                top: 15px
                left: 20px
                color: #888da8
                font-size: .92rem
                line-height: 1.42857
                pointer-events: none
                transition: .3s ease all

            .text-preview
                display: block
                font-size: .8rem
                margin-top: 35px
                margin-left: 15px

            .content_select
                width: 100%
                height: 100%
                padding: 11px 5px 0 5px
                border-bottom: 1px solid $borderHeader
                display: flex
                justify-content: space-between
                cursor: pointer

                &.error
                    border-bottom: 1px solid red !important

                .text-select
                    color: #888da8
                    font-size: .85rem
                    
                .list-select
                    flex: 1
                    display: flex
                    flex-wrap: wrap

                .specialty
                    padding: 2px 5px
                    border: .05rem solid $borderHeader
                    font-size: .8rem
                    color: #888da8
                    border-radius: 5px
                    margin: 5px
                    display: block
            
                .icon-down
                    margin-top: 5px
                    color: $borderHeader

                &:hover ~ .container-hover
                    visibility: visible

            .container-hover
                width: 100%
                height: fit-content
                position: absolute
                top: 100%
                left: 0
                visibility: hidden
                z-index: 1
                padding: 0 15px

                .content-hover
                    width: 100%
                    height: fit-content
                    max-height: 140px
                    overflow-y: auto
                    background-color: $bgContainers
                    border-radius: 0 0 10px 10px

                .select__item
                    width: 100%
                    height: 35px
                    color: white
                    cursor: pointer
                    font-size: .9rem
                    text-align: center
                    padding: 10px 5px

                    &:hover
                        background-color: $bgSelectHover

                    &:last-child
                        border-radius: 0 0 10px 10px

                    .text__item
                        width: 70%
                        text-align: center

                    .icon__item
                        color: white

                &:hover
                    visibility: visible          

            .btn-save, .btn-cancel
                color: white
                background-color: $borderHeader
                padding: 10px 25px
                border-radius: 5px
                cursor: pointer
                font-size: .85rem
                position: relative
                border: 0px

            .btn-cancel
                background-color: transparent
                margin-right: 15px
                border: 1px solid rgba(128,128,128,.8)
                color: rgb(128,128,128)

            .text-free-mail
                margin: 0 auto
                font-size: .75rem
                color: #888da8
                text-decoration: underline
                cursor: pointer 


        .is-focused

            .option-placeholder
                top: 5px
                font-size: 11px
                line-height: 1.07143

            .option-input
                box-shadow: none

        .register-btn, .confirm-btn, .cancel-btn
            position: relative
            bottom: 0
            right: 0
            margin: 10px auto
            border-radius: 3px
            padding: 10px 40px
            background-color: $borderHeader
            color: white
            cursor: pointer
            font-size: .75rem
            width: 135px
            height: fit-content

            &.disabled
                cursor: not-allowed
      
        .cancel-btn
            background-color: $bgSelectHover

        .confirm-btn
            transition: width .3s, height .3s, padding .3s, border-radius .3s, bottom 1s, right 1s

            &:after
                content: ''
                position: absolute
                width: 0
                height: 0
                border-radius: 50%
                background-color: $borderHeader
                transition: width .5s .6s, height .5s .6s            

            &.animated
                //position: absolute 
                // bottom: 45%
                bottom: 15rem
                // right: 45%
                right: 10%
                width: 6rem
                height: 6rem
                padding: 5px
                border-radius: 50%
                font-size: 0

                &:after
                    width: 150vw
                    height: 150vw

.container_form
    width: 90%
    margin: 0 auto
    padding-bottom: 60px
    max-width: 1000px
  
.form
    max-width: 1000px
    align-content: center
    width: 100%

    .less-more
        width: 30px
        
        .icon-more, .icon-less
            font-size: 1.2rem
            color: $borderHeader
            cursor: pointer
            width: 30px
            height: 100%
            float: left

    .row
        .form-file
            display: flex
            align-items: flex-end
            margin-bottom: 1rem
            position: relative

        .btn-file
            color: $textAta
            font-size: .7rem
            height: 22px
            padding: 0 5px
            border-radius: 5px
            border: 1px solid $borderHeader
            position: relative

            .input_file
                position: absolute
                width: 100%
                height: 100%
                top: 0
                left: 0
                opacity: 0
                cursor: pointer
        
        .text-file
            flex: 1
            margin-left: 5px
            border-bottom: 1px solid $borderHeader
            font-size: .85rem
            padding-left: 10px
      
        .text-info-file
            position: absolute
            top: 102%
            left: 0
            color: rgba(gray, .5)
            font-size: .8rem
            margin-left: 15px

        .form-group
            position: relative

        .label
            color: #888da8
            font-size: .85rem
            line-height: 1.42857            

        .form-control
            border: 0
            border-radius: 0
            padding-bottom: 0
            padding-top: 15px
            border-bottom: 1px solid $borderHeader
            box-shadow: none
            font-size: .9rem

            &.error
                border-bottom: 1px solid red !important            

            &:focus
                box-shadow: none

            & ~ .label-placeholder
                top: 3px
                font-size: 0.85rem
                line-height: 0.77143

            & ~ .icon-calendar
                top: 5px											          

            &:valid ~ .label-placeholder
                top: 3px
                font-size: 0.85rem
                line-height: 0.77143 


        textarea
            border: 1px solid $borderHeader !important 
            resize: none
            border-radius: 5px !important
            padding-top: 5px !important
        
            &.error
                border: 1px solid red !important                 
      
        .label-placeholder
            position: absolute
            top: 15px
            left: 20px
            color: #888da8
            font-size: .92rem
            line-height: 1.42857
            pointer-events: none
            transition: .3s ease all

        .text-preview
            display: block
            font-size: .8rem
            margin-top: 35px
            margin-left: 15px

        .content_select
            width: 100%
            height: 100%
            padding: 11px 5px 0 5px
            border-bottom: 1px solid $borderHeader
            display: flex
            justify-content: space-between
            cursor: pointer

            &.error
                border-bottom: 1px solid red !important

            .text-select
                color: #888da8
                font-size: .85rem
            
            .list-select
                flex: 1
                display: flex
                flex-wrap: wrap

            .specialty
                padding: 2px 5px
                border: .05rem solid $borderHeader
                font-size: .8rem
                color: #888da8
                border-radius: 5px
                margin: 5px
                display: block
        
            .icon-down
                color: $borderHeader
                margin-top: 5px
                margin-left: auto

            &:hover ~ .container-hover
                visibility: visible

        .container-hover
            width: 100%
            height: fit-content
            position: absolute
            top: 100%
            left: 0
            visibility: hidden
            z-index: 1
            padding: 0 15px

            .content-hover
                width: 100%
                height: fit-content
                max-height: 140px
                overflow-y: auto
                background-color: $bgContainers
                border-radius: 0 0 10px 10px

            .select__item
                width: 100%
                height: 35px
                color: white
                cursor: pointer
                font-size: .9rem
                text-align: center
                padding: 10px 5px

                &:hover
                    background-color: $bgSelectHover

                &:last-child
                    border-radius: 0 0 10px 10px

                .text__item
                    width: 70%
                    text-align: center

                .icon__item
                    color: white

            &:hover
                visibility: visible          

        .btn-save, .btn-cancel
            color: white
            background-color: $borderHeader
            padding: 10px 25px
            border-radius: 5px
            cursor: pointer
            font-size: .85rem
            position: relative
            border: 0px
            margin: 10px

        .btn-cancel
            background-color: transparent
            margin-right: 15px
            border: 1px solid rgba(128,128,128,.8)
            color: rgb(128,128,128)

        .text-free-mail
            margin: 0 auto
            font-size: .75rem
            color: #888da8
            text-decoration: underline
            cursor: pointer 
 
.formfilter
    max-width: 1000px
    align-content: center
    width: 100%

    .row

        .form-group
            position: relative
          
        .content_select
            width: 100%
            height: 100%
            display: flex
            justify-content: space-between
            cursor: pointer
            padding: unset !important 
            border-bottom: unset !important 
    
            &.error
                border-bottom: 1px solid red !important

            .text-select
                font-size: .8rem
                text-transform: uppercase
                color: rgba(255, 255, 255, 0.7)
                margin-left: 10px
            
            .list-select
                flex: 1
                display: -webkit-box
                flex-wrap: wrap
                overflow: hidden !important
                text-overflow: ellipsis

            .specialty
                padding: 2px 5px
                border: .05rem solid $borderHeader
                font-size: .8rem
                color: #888da8
                border-radius: 5px
                margin: 5px
                display: block
        
            .icon-down
                color: $borderHeader
                margin-top: 5px
                margin-left: auto

            &:hover ~ .container-hover
                visibility: visible

        .container-hover
            width: 100%
            height: fit-content
            position: absolute
            top: 100%
            left: 0
            visibility: hidden
            z-index: 1
            padding: 0 15px

            .content-hover
                width: 100%
                height: fit-content
                max-height: 140px
                overflow-y: auto
                background-color: $bgContainers
                border-radius: 0 0 10px 10px

            .select__item
                width: 100%
                height: 35px
                color: white
                cursor: pointer
                font-size: .9rem
                text-align: center
                padding: 10px 5px

                &:hover
                    background-color: $bgSelectHover

                &:last-child
                    border-radius: 0 0 10px 10px

                .text__item
                    width: 70%
                    text-align: center

                .icon__item
                    color: white

            &:hover
                visibility: visible          






    .container-modal
        position: fixed
        top: -200vh
        left: 0
        z-index: 100
        width: 100%
        height: 100vh
        background-color: rgba(black, .8)
        transition: all .5s

    &.activated
        top: 0
  
  
  
    .content-modal
        width: 90%
        max-height: 80%
        background-color: white
        border-radius: 5px
        overflow-y: auto
    
    .medium
        width: 50% !important
    
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap'

.dataRequired
    color: #ff5630
    font-weight: 700

// Scrol personalizado para el sitio
.myscroll
  margin-top: 5px
  width: 100%
  height: 94vh
  overflow-y: auto !important

  &::-webkit-scrollbar
    -webkit-appearance: none

  &::-webkit-scrollbar:vertical
    width: 10px

  &::-webkit-scrollbar-button:increment,
  &::-webkit-scrollbar-button
    display: none

  &::-webkit-scrollbar:horizontal
    height: 10px

  &::-webkit-scrollbar-thumb
    background-color: #80ba27
    border-radius: 20px
    border: 2px solid #f1f2f3

  &::-webkit-scrollbar-track
    border-radius: 10px

// Scroll de tablas de datos (DataTables)
/* Reducir el grosor del scroll horizontal */
.dataTables_scrollBody
  &::-webkit-scrollbar
    width: 7px // Grosor vertical
    height: 7px // Grosor horizontal más delgado
    border-radius: 20px

  /* Fondo del track */
  &::-webkit-scrollbar-track
    background-color: #80ba27
    border-radius: 20px
    border: 2px solid #f1f2f3

  /* Color de la barra deslizante */
  &::-webkit-scrollbar-thumb
    background-color: #f1f2f3
    border-radius: 20px
    border: 2px solid #80ba27

    /* Efecto al pasar el mouse */
    &:hover
      background-color: #d0d0d0

// .myscroll
//   &::-webkit-scrollbar
//     -webkit-appearance: none

//   &::-webkit-scrollbar:vertical
//     width: 10px

//   &::-webkit-scrollbar-button:increment,
//   &::-webkit-scrollbar-button
//     display: none

//   &::-webkit-scrollbar:horizontal
//     height: 10px

//   &::-webkit-scrollbar-thumb
//     background-color: #80ba27
//     border-radius: 20px
//     border: 2px solid #f1f2f3

//   &::-webkit-scrollbar-track
//     border-radius: 10px






