$white: #FFFFFF
$bgSelectHover: #A4AFB7
$maintenance: #3B86FF
$textAta: #0B1141
$bgServiceSelect: #17243E
$colorText: #666666
$bgFilter: #EAEAEA
$colorSeparator: #8E8C8C
$bgContents: #F7F7F7
$borderHeader: #80BA27
$colorModal: #55b21a
$borderHeaderOpacity: #669A2F
$operative: #91B83B
$bgContainers: #2A2E43
$bgContainersOpacity: #353A50
$separatorBorder: #F1F1F3
$textBg: #43425D
$textPagination: #4D4F5C
$bgContainersTwo: #16253E
$maskBg: #78B9EB
$bgOptionHover: #A3AEB6
$textDateLicences: #454F63
$textLicences: #78849E
$separatorBorderRight: #F4F4F6
$textAdd: #454545
$textSubtitle: #8B8B8B
$colorIconAdd: #006DF0
$bgTaksContainer: #F2F2F2
$underline: #A1A2A1
$imprevistos: #E02424
$majorMaintenance: #C341F9
$notOpetarional: #FFB64D
$dischargeProcess: #FC8730
$textStatus: #25292D
$containerDragAndDrop: #F4F4F4
$textTask: #6A6A6A
$bgSelectResource: #CDCDCD
$colorAlert: #FF6565
$colorDescription: #808080
$colorFilter: #A6AFB6
$bgTabActivated: #A4AFB7
$colorTextGrap: #0A1141
$colorTextOpt: #A2A2A2
$borderGrap: #D0D0D0
$colorProgress: #F6C544
$colorNumberProgress: #17243E
$colorIcons: #707070
$bgAssigned: #B2B4BC
$bgMaint: #E8C503
$bgAMI: #E24444
$bgNotOperational: #4487E2
$bgItemSelect: #E6EAE1
$red: #FF0000
$bgHover: #FFFFFF4B
$placeholder: rgba(255,255,255,0.7)
$colorMachineMap: #86E2DF
$bgMachineMap: #141F37

$primaryColor: #80BA27
$primaryVariantColor: #55b21a
$primaryTransparent: #80BA277a
$onPrimaryColor: #f7f7f7

$secondaryColor: #0a1141
$secundaryVariantColor: #2A2E43
$onSecondaryColor: #f7f7f7

$backgroundColor: #FFFFFF
$backgroundModalColor: #000
$onBackground: #000

$backgroundVariantColor: #16253E
$onBackgroundVariant: #FFFFFF

$surfaceColor: #F2F2F2
$onSurfaceColor: #0B1141

$filterBackgroundColumn: #252837

$btnPrimaryColor: #55B235
$btnInfoColor: #0B123F
$btnPrimaryColorTxt: #FFFFFF
$btnBorderRadius: 2px

$CardtittleIconColor: white
$CardtittleTextColor: white
$CardContentTextColor: #0B1141
$CardTableTittlebgColor: #E8E8E8
$CardBgColor: white

$inputOutlineColor: #80ba27
$inputBgColor: #f2f2f2
$inputTextColor: #0b1141

$selectBgColor: #f2f2f2
$selectTextColor: #0b1141

// greys
$greyBackground: #E0E0E0
$greyText: #5D5D5D
$onGreyBackground: #7D7D7D



$ErrorColor: #e60a60
$onError: #FFFFFF

$mrg1: 0.5rem
$mrg2: 0.6rem
$mrg3: 1.2rem

$zIdzBack: -100
$zIdzZero: 0
$zIdz1: 1
$zIdz2: 2
$zIdz3: 3
$zIdzTop: 100

$menuheight: 50px
$menuAfterH: 10px
$totalMenuHeigth:  calc( #{$menuheight} + #{$menuAfterH})

$cardBorderRadius: 5px

$backgroundColorTabList: #0B1141
